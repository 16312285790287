import React, { useState } from 'react';
import Heading from 'components/design-system/Heading/Heading';
import Text from 'components/design-system/Text/Text';
import { ICON_CAMBIO_CLIMATICO } from 'icons/ReporteIndicadores/IconCambioClimatico';
import { ICON_MATERIAL_PARTICULADO } from 'icons/ReporteIndicadores/IconMaterialParticulado';
import { ICON_DEMANDA_ACUMULADA } from 'icons/ReporteIndicadores/IconDemandaAcumulada';
import { ICON_AGOTAMIENTO_FOSIL } from 'icons/ReporteIndicadores/IconAgotamientoFosil';
import { ICON_PARIDAD_MUJERES } from 'icons/ReporteIndicadores/IconParidadMujeres';
import { ICON_HORAS_DE_TRABAJO } from 'icons/ReporteIndicadores/IconHorasDeTrabajo';
import { ICON_SALARIO } from 'icons/ReporteIndicadores/IconSalario';
import { formatoANumeroIngles } from 'helpers/formateoNumeros';
import IconButtonComponent from 'components/design-system/IconButton/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ButtonComponent from 'components/design-system/Button/Button';
import IconComponent from 'components/design-system/icon/Icon';
import { Cifra } from '../Reporte/Cifra';
import { Info } from '@mui/icons-material';

const ResultadoIndicadores = ({ data, onTryAgain }) => {
  const [indicadores] = useState({
    marketplace: {
      destacado: {
        titulo: 'CAMBIO CLIMÁTICO',
        descripcion: 'kg CO2eq',
        icon: <ICON_CAMBIO_CLIMATICO />,
        valor: data?.ambiental?.cambioClimatico
          ? formatoANumeroIngles(
              data.ambiental.cambioClimatico.ambiental.toString()
            )
          : ''
      },
      principal: [
        {
          titulo: 'AGOTAMIENTO DE RECURSO FÓSIL',
          descripcion: 'kg petróleo eq',
          icon: <ICON_AGOTAMIENTO_FOSIL />,
          valor: data?.ambiental?.recursoFosil
            ? formatoANumeroIngles(
                data.ambiental.recursoFosil.ambiental.toString()
              )
            : ''
        },
        {
          titulo: 'FORMACIÓN DE MATERIAL PARTICULADO',
          descripcion: 'kgmp 2,5eq',
          icon: <ICON_MATERIAL_PARTICULADO />,
          valor: data?.ambiental?.materialParticulado
            ? formatoANumeroIngles(
                data.ambiental.materialParticulado.ambiental.toString()
              )
            : ''
        },
        {
          titulo: 'DEMANDA ACUMULADA DE ENERGÍA',
          descripcion: 'MJ eq',
          icon: <ICON_DEMANDA_ACUMULADA />,
          valor: data?.ambiental?.demandaAcumulada
            ? formatoANumeroIngles(
                data.ambiental.demandaAcumulada.ambiental.toString()
              )
            : ''
        }
      ]
    },
    sociales: {
      principal: [
        {
          titulo: 'HORA DE TRABAJO',
          descripcion: 'H totales',
          icon: <ICON_HORAS_DE_TRABAJO />,
          valor: data?.social?.horasTrabajo
            ? formatoANumeroIngles(data.social.horasTrabajo.toString())
            : '0'
        },
        {
          titulo: 'PARIDAD MUJERES',
          descripcion: 'HM',
          icon: <ICON_PARIDAD_MUJERES />,
          valor: data?.social?.horasTrabajoMujer
            ? formatoANumeroIngles(data.social.horasTrabajoMujer.toString())
            : '0'
        },
        {
          titulo: 'SALARIO',
          descripcion: 'Mil pesos',
          icon: <ICON_SALARIO />,
          valor: data?.social?.salario
            ? formatoANumeroIngles(data.social.salario.toString())
            : '0'
        }
      ]
    }
  });
  const mostrarIndicadoresSociales =
    data?.social?.horasTrabajo &&
    data?.social?.horasTrabajoMujer &&
    data?.social?.salario
      ? true
      : false;

  return (
    <div>
      <div className="mb-4">
        <Heading type="h2" className="mb-0">
          <IconButtonComponent
            onClick={onTryAgain}
            type="secondary"
            className="mr-2"
          >
            <ChevronLeft />
          </IconButtonComponent>
          Indicadores obtenidos
        </Heading>
        <Text className="mb-0">
          Según los datos ingresados estos indicadores hemos calculado.
        </Text>
      </div>

      <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br mb-4 shadow-md">
        <div className="grid grid-cols-12 items-center gap-4">
          <div className="col-span-12 lg:col-span-4">
            <Heading
              type="h3"
              className="text-uv-secondary-0 font-bold mb-0 text-2xl"
            >
              Indicadores de
              <br />
              impacto ambiental
            </Heading>
          </div>
          <div className="col-span-12 lg:col-span-8">
            <IndicadorItem
              size="big"
              title={
                indicadores.marketplace?.destacado?.titulo
                  ? indicadores.marketplace.destacado.titulo
                  : ''
              }
              value={
                indicadores.marketplace?.destacado?.valor
                  ? indicadores.marketplace.destacado.valor
                  : ''
              }
              description={
                indicadores.marketplace?.destacado?.descripcion
                  ? indicadores.marketplace.destacado.descripcion
                  : ''
              }
              icon={
                indicadores.marketplace?.destacado?.icon
                  ? indicadores.marketplace.destacado.icon
                  : ''
              }
            />
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 mt-2 gap-5">
          {indicadores.marketplace.principal.map((indicador, index) => (
            <IndicadorItem
              title={indicador.titulo ? indicador.titulo : ''}
              value={indicador.valor ? indicador.valor : ''}
              description={indicador.descripcion ? indicador.descripcion : ''}
              icon={indicador.icon ? indicador.icon : ''}
            />
          ))}
        </div>
      </div>
      <div className="flex-grow w-auto px-6 py-4 bg-white rounded-tr rounded-br mb-4 shadow-md">
        <div className="grid grid-cols-12 items-center gap-4">
          <div className="col-span-12">
            <Heading
              type="h3"
              className="text-uv-secondary-0 font-bold mb-0 text-2xl"
            >
              Indicadores Sociales
            </Heading>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 mt-2 gap-5">
          {indicadores.sociales.principal.map((indicador, index) => (
            <IndicadorItem
              title={indicador.titulo ? indicador.titulo : ''}
              value={indicador.valor ? indicador.valor : ''}
              description={indicador.descripcion ? indicador.descripcion : ''}
              icon={indicador.icon ? indicador.icon : ''}
              esOpcional={indicador.valor.toString() === '0'}
            />
          ))}
        </div>
        {!mostrarIndicadoresSociales && (
          <div className="bg-info-light p-4 rounded flex items-center gap-3 mt-4">
            <Info className="text-info-dark" />
            <Text className="text-info-dark">
              No se han obtenido información para los indicadores sociales ya
              que no has ingresado información de esta en el formulario
              anterior.
            </Text>
          </div>
        )}
      </div>

      <div className="mb-10">
        <ButtonComponent
          type="secondary"
          onClick={onTryAgain}
          className="justify-center"
        >
          <ChevronLeft /> Volver a intentar
        </ButtonComponent>
      </div>
    </div>
  );
};

const IndicadorItem = ({
  title,
  value,
  description,
  icon,
  size,
  esOpcional
}) => {
  return (
    <div className={`w-full flex-wrap py-3 ${esOpcional ? 'opacity-40' : ''}`}>
      <Text
        type="label"
        className={`text-uv-primary-0 mb-2 pr-3 ${
          size === 'big' ? 'text-sm' : ''
        } `}
      >
        {title}
      </Text>
      <div className="flex items-center">
        <IconComponent
          color="primary"
          className={`bg-uv-primary-90 rounded-full flex items-center justify-center
            ${size === 'big' ? 'w-20 h-20 p-3' : 'w-14 h-14 p-2.5'} 
          `}
        >
          {icon}
        </IconComponent>

        <div className="pl-3">
          <Heading
            type="H2"
            className={`font-bold my-0 ${size === 'big' ? 'text-3xl' : ''} `}
          >
            <Cifra valor={value} />
          </Heading>
          <Text className="text-black leading-5 font-normal">
            {description}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default ResultadoIndicadores;
