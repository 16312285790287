import React, { useContext } from 'react';
import { withStyles } from '@mui/styles';
// Date Picker
import { es } from 'date-fns/locale';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// Material UI
import { ListItem, Tooltip } from '@mui/material';
import { Place, Help } from '@mui/icons-material';
// Design System
import AutocompleteComponent from 'components/design-system/Autocomplete/Autocomplete';
import Heading from 'components/design-system/Heading/Heading';
import InputComponent from 'components/design-system/input/Input';
import InputNumber from 'components/design-system/input/InputNumber';
import LabelComponent from 'components/design-system/Label/Label';
import ListComponent from 'components/design-system/List/List';
import { TooltipComponent } from 'components/design-system/Tooltip/Tooltip';

// Hooks
import {
  ObtieneLevantamiento,
  ObtieneListaSucursales,
  ObtieneListaTipoDisposicion
} from 'hooks';
// Components
import { TablaConversion } from 'views/Residuos/components/Others/TablaConversion';

// Const
import { DICTONARY } from 'const/Dictonary';
// Helpers
import usePrecioReferencial from 'views/Residuos/hooks/Helpers/usePrecioReferencial';
// Utils
import { tooltipText } from 'views/Residuos/utils/utils';
// Data
import { frecuencia_options } from 'views/Residuos/data/espesificacionOferta';
import { AuthContext } from 'context';
import { DatePickerComponent } from 'components/design-system/DatePicker/DatePicker';
// Services
import { ObtieneUnidadMedida } from 'hooks/useServices';
// Permisos
import { VerificarPermisos } from 'components/VerificarPermisos/VerificarPermisos';
import { PERMISOS } from 'const/Permisos';
import Text from 'components/design-system/Text/Text';

const Tooltipcolor = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: '12px'
  }
}))(Tooltip);

const EspesificacionOferta = ({
  errors,
  handleChange,
  isSubmitting,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
  tipoFormularioSeleccionado
}) => {
  const {
    perfiles: { isSuperAdmin },
    logeduser
  } = useContext(AuthContext);
  const { ingresar, editar, duplicar } = tipoFormularioSeleccionado;

  const getCodigoEmpresa = () => {
    const codigoEmpresaInter = logeduser?.sucursal?.empresa.codigoEmpresa;
    const codigoEmpresa =
      editar || duplicar
        ? isSuperAdmin
          ? values?.empresa?.codigoEmpresa
          : !isSuperAdmin
          ? values?.empresa?.codigoEmpresa
          : null
        : ingresar
        ? codigoEmpresaInter
        : null;

    return codigoEmpresa;
  };

  const {
    listaSucursales: sucursales,
    cargandoListaSucursales: isLoadingSucursales
  } = ObtieneListaSucursales(getCodigoEmpresa());

  const { handleFocusPrecioReferencial } = usePrecioReferencial({
    setFieldTouched,
    setFieldValue
  });

  const { listaTipoDisposicion } = ObtieneListaTipoDisposicion(
    values.tipoResiduo?.codigoTipoResiduo
  );

  const { levantamiento, isLoadingLevantamiento } = ObtieneLevantamiento();

  const { unidadMedida, isLoadingUnidadMedida } = ObtieneUnidadMedida();

  return (
    <div className="col-span-12 mt-4">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-12">
          <Heading type="h4" className="mb-0 text-uv-primary-10">
            Especificación de oferta
          </Heading>
        </div>
        <div className="col-span-12 xs:col-span-12">
          {/* Fila 1 - Sucursal de origen */}
          <VerificarPermisos nombrePermiso={PERMISOS.sucursalOrigenEmpresa}>
            <div className="grid grid-cols-12 gap-4 align-middle">
              {/* Sucursales */}
              <div className="col-span-12 xs:col-span-4">
                <AutocompleteComponent
                  accesor="nombreSucursal"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  name={'sucursal'}
                  options={sucursales || []}
                  value={values.sucursal || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'sucursal', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    const direccion = newValue ? newValue.direccion : {};
                    const nombreDireccion = `${direccion.nombreVia}, ${direccion.comuna?.nombreComuna}, Región ${direccion.comuna?.region?.nombreRegion} `;

                    setFieldValue('nombreDireccionSucursal', nombreDireccion);

                    setFieldValue('sucursal', data.value);
                    setFieldTouched('fechaTransaccion');
                  }}
                  getOptionLabel={option => option.nombreSucursal || ''}
                  noOptionsText={
                    isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                  }
                >
                  <InputComponent
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingSucursales}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched('sucursal');
                    }}
                    touched={touched.sucursal}
                    placeholder={
                      isLoadingSucursales
                        ? 'Cargando...'
                        : 'Selecciona una opción'
                    }
                    estado={
                      errors.sucursal
                        ? { tipo: 'error', mensaje: errors.sucursal }
                        : null
                    }
                    label={<LabelComponent>Sucursal de origen</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>

              {/* Dirección */}
              <div className="col-span-12 xs:col-span-8 sm:pl-2 sm:pt-4 sm:mt-4">
                <Text>
                  <Place className="text-uv-secondary-0" />
                  {values.nombreDireccionSucursal
                    ? values.nombreDireccionSucursal
                    : 'Seleccionar sucursal'}
                </Text>
              </div>
            </div>
          </VerificarPermisos>
          <VerificarPermisos nombrePermiso={PERMISOS.sucursales}>
            <div className="grid grid-cols-12 gap-4 align-middle">
              {/* Sucursales */}
              <div className="col-span-12 xs:col-span-4">
                <AutocompleteComponent
                  accesor="nombreSucursal"
                  clearOnEscape={true}
                  disabled={isSubmitting || isLoadingSucursales}
                  fullWidth
                  name={'sucursal'}
                  options={sucursales || []}
                  value={values.sucursal || ''}
                  onChange={(event, newValue, reason) => {
                    let data = { name: 'sucursal', value: newValue };
                    if (reason === 'clear') {
                      data.value = '';
                    }
                    const direccion = newValue ? newValue.direccion : {};
                    const nombreDireccion = `${direccion.nombreVia}, ${direccion.comuna?.nombreComuna}, Región ${direccion.comuna?.region?.nombreRegion} `;

                    setFieldValue('nombreDireccionSucursal', nombreDireccion);

                    setFieldValue('sucursal', data.value);
                    setFieldTouched('fechaTransaccion');
                  }}
                  getOptionLabel={option => option.nombreSucursal || ''}
                  noOptionsText={
                    isLoadingSucursales ? 'Cargando...' : 'Sin opciones'
                  }
                >
                  <InputComponent
                    autoComplete="off"
                    disabled={isSubmitting || isLoadingSucursales}
                    fullWidth
                    onBlur={() => {
                      setFieldTouched('sucursal');
                    }}
                    touched={touched.sucursal}
                    placeholder={
                      isLoadingSucursales
                        ? 'Cargando...'
                        : 'Selecciona una opción'
                    }
                    estado={
                      errors.sucursal
                        ? { tipo: 'error', mensaje: errors.sucursal }
                        : null
                    }
                    label={<LabelComponent>Sucursal de origen</LabelComponent>}
                  />
                </AutocompleteComponent>
              </div>

              {/* Dirección */}
              <div className="col-span-12 xs:col-span-8 sm:pl-2 sm:pt-4 sm:mt-4">
                <Text>
                  <Place className="text-uv-secondary-0" />
                  {values.nombreDireccionSucursal
                    ? values.nombreDireccionSucursal
                    : 'Seleccionar sucursal'}
                </Text>
              </div>
            </div>
          </VerificarPermisos>

          {/* Fila 2 - precio referencial */}
          <div className="grid grid-cols-12 gap-4 mt-4">
            {/* Precio Referencial */}
            <VerificarPermisos nombrePermiso={PERMISOS.precioReferencial}>
              <div className="col-span-12 xs:col-span-4">
                <InputNumber
                  maxDecimals={DICTONARY.NUMERO_DECIMALES}
                  type="text"
                  prefix={'$'}
                  onFocus={handleFocusPrecioReferencial}
                  label={
                    <LabelComponent>
                      Precio Referencial
                      <TooltipComponent
                        className="float-right"
                        title={tooltipText}
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                  name="precioReferencial"
                  onChange={handleChange}
                  touched={touched.precioReferencial}
                  onBlur={e => {
                    setFieldTouched('precioReferencial');
                    setFieldTouched('frecuencia', true);
                  }}
                  value={
                    values.precioReferencial_str || values.precioReferencial
                  }
                  placeholder={
                    !values.precioReferencial ? 'Ingrese precio referencia' : ''
                  }
                  estado={
                    errors.precioReferencial
                      ? {
                          tipo: 'error',
                          mensaje: errors.precioReferencial
                        }
                      : null
                  }
                />
              </div>
            </VerificarPermisos>

            {/* Frecuencia  */}
            <VerificarPermisos nombrePermiso={PERMISOS.frecuencia}>
              <div className="col-span-12 xs:col-span-4">
                <AutocompleteComponent
                  className="mb-3 "
                  clearOnEscape={true}
                  disabled={isLoadingSucursales}
                  id="frecuencia"
                  name="frecuencia"
                  accesor="label"
                  openOnFocus={false}
                  size="small"
                  options={frecuencia_options}
                  onBlur={() => {
                    setFieldTouched('frecuencia');
                    setFieldTouched('cantidad', true);
                  }}
                  value={
                    frecuencia_options.find(
                      c => c.value === values.frecuencia
                    ) || ''
                  }
                  getOptionLabel={option => (option ? option.label : '')}
                  noOptionsText={'Sin opciones'}
                  onChange={(ev, selected) => {
                    if (!selected) return;
                    setFieldValue('frecuencia', selected.value);
                  }}
                >
                  <InputComponent
                    className="h-9"
                    fullWidth
                    placeholder={
                      frecuencia_options.length ? 'Seleccione frecuencia' : ''
                    }
                    touched={touched.frecuencia}
                    estado={
                      errors.frecuencia
                        ? {
                            tipo: 'error',
                            mensaje: errors.frecuencia
                          }
                        : null
                    }
                    label={
                      <>
                        <LabelComponent>Frecuencia</LabelComponent>
                      </>
                    }
                  />
                </AutocompleteComponent>
              </div>
            </VerificarPermisos>

            {/* Fecha de disponibilidad */}
            <VerificarPermisos nombrePermiso={PERMISOS.fechaDisponibilidad}>
              <div className="col-span-12 xs:col-span-4">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <DesktopDatePicker
                    className="mb-4 w-full"
                    name="fechaDisponibilidad"
                    label={
                      <LabelComponent>Fecha Disponibilidad</LabelComponent>
                    }
                    inputFormat="dd/MM/yyyy"
                    value={values.fechaDisponibilidad}
                    defaultValue={values.fechaDisponibilidad}
                    maxDate={values.fechaDisponibilidadFin}
                    onChange={value => {
                      handleChange({
                        target: {
                          name: 'fechaDisponibilidad',
                          value: value
                        }
                      });
                    }}
                    slots={{ textField: DatePickerComponent }}
                  />
                </LocalizationProvider>
              </div>
            </VerificarPermisos>
          </div>

          {/* Fila 3 */}
          <div className="grid grid-cols-12 gap-4 mt-2 ">
            <div className="col-span-12 xs:col-span-4">
              {/* Cantidad */}
              <VerificarPermisos nombrePermiso={PERMISOS.cantidad}>
                <div className="col-span-12 xs:col-span-12 ">
                  <InputNumber
                    autoComplete="off"
                    disabled={isSubmitting}
                    label={<LabelComponent>Cantidad</LabelComponent>}
                    maxDecimals={DICTONARY.NUMERO_DECIMALES}
                    name={'cantidad'}
                    placeholder={!values.cantidad ? '' : ''}
                    touched={touched.cantidad}
                    type="text"
                    value={values.cantidad_str || values.cantidad || ''}
                    onChange={event => {
                      const { value } = event.target;
                      if (value === '') {
                        setFieldValue('cantidad', '');
                        setFieldValue('cantidad_str', '');
                        setFieldTouched('cantidad');
                        return;
                      }
                      const sanitizedValue = value
                        .replaceAll('.', '')
                        .replaceAll(',', '.');
                      const floatValue = parseFloat(sanitizedValue);
                      if (!isNaN(floatValue)) {
                        setFieldValue('cantidad', floatValue);
                      }
                      setFieldTouched('unidadMedida', true);
                    }}
                    onFocus={event => {
                      const { value } = event.target;
                      setFieldTouched('cantidad');
                      if (
                        !event ||
                        !event.target ||
                        typeof event.target.value !== 'string'
                      ) {
                        return;
                      }
                      let sanitizedValue = value.split('.').join('') || '';
                      setFieldValue('cantidad_str', sanitizedValue);
                    }}
                    onBlur={() => {
                      setFieldTouched('cantidad');
                    }}
                    estado={
                      errors.cantidad
                        ? { tipo: 'error', mensaje: errors.cantidad }
                        : null
                    }
                  />
                </div>
              </VerificarPermisos>

              {/* Formato de entrega */}
              <VerificarPermisos nombrePermiso={PERMISOS.formatoEntrega}>
                <InputComponent
                  className={''}
                  autoComplete={'off'}
                  fullWidth
                  label={
                    <LabelComponent className="mt-2">
                      Formato de entrega
                      <TooltipComponent
                        className="float-right"
                        title={
                          'Indica la forma en el que tú residuo fue retirado, si necesitaste algún envase o embalaje para retirarlo o sólo se fue a granel.'
                        }
                      >
                        <Help
                          className="text-uv-secondary-0"
                          fontSize="small"
                        />
                      </TooltipComponent>
                    </LabelComponent>
                  }
                  name="formatoEntrega"
                  onChange={handleChange}
                  onBlur={() => setFieldTouched('formatoEntrega')}
                  touched={touched.formatoEntrega}
                  value={values.formatoEntrega}
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder={!values.formatoEntrega ? 'Ingrese formato' : ''}
                  estado={
                    errors.formatoEntrega
                      ? {
                          tipo: 'error',
                          mensaje: errors.formatoEntrega
                        }
                      : null
                  }
                />
              </VerificarPermisos>
            </div>

            <div className="col-span-12 xs:col-span-4">
              {/* Unidad de Medida  */}
              <VerificarPermisos nombrePermiso={PERMISOS.unidadMedida}>
                <div className="col-span-12 xs:col-span-12 sm:col-span-6 lg:col-span-7">
                  <AutocompleteComponent
                    accesor="nombreUnidadMedida"
                    clearOnEscape={true}
                    disabled={isSubmitting || isLoadingUnidadMedida}
                    fullWidth
                    name={'unidadMedida'}
                    options={unidadMedida || []}
                    value={values.unidadMedida || ''}
                    onChange={(event, newValue, reason) => {
                      let data = { name: 'unidadMedida', value: newValue };
                      if (reason === 'clear') {
                        data.value = '';
                      }
                      setFieldValue('unidadMedida', data.value);
                      setFieldTouched('tipoTransporte');
                    }}
                    getOptionLabel={option => option.nombreUnidadMedida || ''}
                    noOptionsText={
                      isLoadingUnidadMedida ? 'Cargando...' : 'Sin opciones'
                    }
                  >
                    <InputComponent
                      autoComplete="off"
                      disabled={isLoadingUnidadMedida}
                      fullWidth
                      onBlur={() => {
                        setFieldTouched('unidadMedida');
                      }}
                      touched={touched.unidadMedida}
                      placeholder={
                        isLoadingUnidadMedida
                          ? 'Cargando...'
                          : 'Selecciona una opción'
                      }
                      estado={
                        errors.unidadMedida
                          ? { tipo: 'error', mensaje: errors.unidadMedida }
                          : null
                      }
                      label={<LabelComponent>Unidad</LabelComponent>}
                    />
                  </AutocompleteComponent>
                </div>
              </VerificarPermisos>
              {/* Levantamiento de datos */}
              <VerificarPermisos nombrePermiso={PERMISOS.levantamientoDatos}>
                <div className="col-span-12 md:col-span-6 xl:col-span-4 mt-2">
                  <AutocompleteComponent
                    accesor="nombreLevantamientoDatos"
                    clearOnEscape={true}
                    disabled={isSubmitting || isLoadingLevantamiento}
                    fullWidth
                    name={'levantamientoDatos'}
                    options={levantamiento}
                    value={values.levantamientoDatos || ''}
                    onChange={(event, newValue, reason) => {
                      let data = {
                        name: 'levantamientoDatos',
                        value: newValue
                      };
                      if (reason === 'clear') {
                        data.value = '';
                      }
                      setFieldValue('levantamientoDatos', data.value);
                    }}
                    getOptionLabel={option =>
                      option.nombreLevantamientoDatos || ''
                    }
                    noOptionsText={
                      isLoadingLevantamiento ? 'Cargando...' : 'Sin opciones'
                    }
                  >
                    <InputComponent
                      autocomplete="off"
                      disabled={isLoadingLevantamiento}
                      fullWidth
                      onBlur={() => {
                        setFieldTouched('levantamientoDatos');
                      }}
                      touched={touched.levantamientoDatos}
                      placeholder={
                        isLoadingLevantamiento
                          ? 'Cargando...'
                          : 'Selecciona una opción'
                      }
                      estado={
                        errors.levantamientoDatos
                          ? {
                              tipo: 'error',
                              mensaje: errors.levantamientoDatos
                            }
                          : null
                      }
                      label={
                        <LabelComponent>
                          Levantamiento de datos{' '}
                          <span className="text-neutral-60">(opcional)</span>
                        </LabelComponent>
                      }
                    />
                  </AutocompleteComponent>
                </div>
              </VerificarPermisos>
            </div>
            <div className="col-span-12 xs:col-span-12">
              <TablaConversion />
              <div className="col-span-12 xs:col-span-4 mt-1">
                <ListComponent>
                  <LabelComponent className="text-black">
                    Tipo de Disposición:
                  </LabelComponent>
                  {listaTipoDisposicion &&
                    listaTipoDisposicion.length === 0 && (
                      <ListItem>
                        <Text>Sin opciones disponibles</Text>
                      </ListItem>
                    )}
                  {listaTipoDisposicion &&
                    listaTipoDisposicion.map((item, i) => (
                      <ListItem key={i}>
                        <Text>{item.nombreDisposicion}</Text>
                      </ListItem>
                    ))}
                </ListComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EspesificacionOferta;
